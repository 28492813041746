import { API_URL } from "../../utils/constants";

export const restaurante = {
  namespaced: true,
  state: {},
  actions: {
    async fetchInfoRestaurante({ commit }, payload) {
      let params = payload.payload;      

      try {
       // alert(`${params.sucursal}`);
        //LLamada a multiples enpoints para obtener valores
        let [res1, res2, res3,res4] = await Promise.all([
          fetch(`${API_URL}/getSucursalByName?sucursal=${params.sucursal}`),
          fetch(`${API_URL}/getPuestos?sucursal=${params.sucursal}`),
          fetch(`${API_URL}/modulos?sucursal=${params.sucursal}`),
          fetch(`${API_URL}/getTranslations`),
          //fetch('/db/translations.json')
        ]);

        // Obtener respuesta de las llamadas al api para configurar el state
        let opciones = await res1.json();
        let puestos = await res2.json();
        let modulos = await res3.json();
        let traducciones = await res4.json();

 
        //Buscar mesa en la llamada del api, si el id es igual al de la mesa, devolvera el numero de mesa, si no es ocurre error.
        var pmesa = params.mesa; 
        if (pmesa == 1)
            pmesa = puestos.puestos[0].id;
       
        let findMesa = puestos.puestos.filter( puesto => puesto.estado === true).find((mesa) => mesa.id == pmesa);

        // Si la busqueda es correcta
        if (findMesa && opciones.Sucursal.activa) {
        
        let [res4] = await Promise.all([
          fetch(`${API_URL}/getIdiomas?sucursal=`+opciones.Sucursal.id)
        ]);
        let idiomas = await res4.json();
        let [res6] = await Promise.all([
          fetch(`${API_URL}/getSizesBySucursal?sucursal=`+opciones.Sucursal.id)
        ]);
        let sizes = await res6.json();
        if (sizes.success == false)
            sizes = {'sizesbysucursal':[{}]};
        let [res7] = await Promise.all([
          fetch(`${API_URL}/configGeneral?sucursal=`+opciones.Sucursal.id)
        ]);

        let general = await res7.json();
        let [res8] = await Promise.all([
          fetch(`${API_URL}/configOpiniones?sucursal=`+opciones.Sucursal.id)
        ]);

        let configresenas = await res8.json()
        if (idiomas.success == false)
            idiomas = {'Idiomas':[{"id":"es","nombre":"español","logo":"es.png","nosotros":null,"estado":"1"}]};
          //Cambiar al simbolo de moneda: 0: Arg 1: USD 3:EUR 
          let moneda;

          switch (opciones.Sucursal.moneda) {
            case 0:
              moneda = "$";
              break;
            case 1:
              moneda = "$";
              break;
            case 3:
              moneda = "€";
              break;
            default:
              moneda = "€"
              break;
          }
        let [res5] = await Promise.all([
          fetch(`${API_URL}/getHorarios?sucursal=`+opciones.Sucursal.id)
        ]);
        let horarios = await res5.json();
          let restaurante = {
            ...opciones.Sucursal,
            mesa: parseInt(findMesa.numero),
            puesto: findMesa.id,
            suplemento: findMesa.suplemento,
            moneda: moneda,
            modulos: modulos.modulos,
            idiomas: idiomas.Idiomas,
            traducciones: traducciones.result,
            horario: horarios.Horarios,
            sizes: sizes.sizesbysucursal,
            general : general.configGeneral,
            cfgresenas: configresenas.configOpiniones
          };

          

          // if (localStorage.getItem('vuex')) {
          //   // LocalStorage disponible
          //   let localStorageSucursal = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.sucursal;
    
          //    if(params.sucursal !== localStorageSucursal) {
          //     // Elimina todos los elementos
          //     localStorage.clear();
          //   }
          // }
     
          commit("agregarInfoRestaurante", restaurante);

          if(opciones) {
            //Verificar en localstorage que el restaurante no sea el mismo, si es otro, borrar toda la data del localstorage
            let localStorageSucursal = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.sucursal;

            if(params.sucursal !== localStorageSucursal) {
              // Elimina todos los elementos
              localStorage.clear();
            }
            let localStoragePuesto = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.puesto;

            if(params.mesa !== localStoragePuesto) {
              // Elimina todos los elementos
              localStorage.clear();
            }
          }

        } else {
          //Esta vacio, mostrar error
          localStorage.clear();
          commit("error", "QR Deshabilitado. Consulte proveedor para más info", { root: true });
        }
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
      }
    }
  },
  mutations: {
    agregarInfoRestaurante(state, payload) {
      state.restaurante = payload;

    }
  },
  getters: {
    logotipo: (state, _, rootState) => {
      if (state.restaurante.logo == null) {
        return "@/assets/images/logo.png";
      } else {
        return rootState.restaurante.restaurante.logo;
      }
    }
  }
};
