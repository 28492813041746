<template>
  <transition name="section">
    <div
      
      v-if="mostrarBoton && muestraCesta"
      class="carrito-compras-boton"
      @click="mostrarCarrito()"
    >
      <div class="icon-compras">
        <svg
          id="Layer_1"
          enable-background="new 0 0 480 480"
          height="24"
          viewBox="0 0 480 480"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m411.9 436.48-24.36-276c-1.83-20.79-18.96-36.48-39.84-36.48-4.797 0-8.7 3.9-8.7 8.7v43.03c0 4.26-3.22 7.98-7.47 8.25-4.66.3-8.53-3.39-8.53-7.98v-93c0-45.77-37.23-83-83-83s-83 37.23-83 83v41h-24.7c-20.88 0-38.01 15.69-39.84 36.48l-24.36 276c-2.05 23.325 16.343 43.52 39.85 43.52h264.1c23.51 0 41.9-20.198 39.85-43.52zm-217.9-9.48h-58c-4.418 0-8-3.582-8-8s3.582-8 8-8h58c4.418 0 8 3.582 8 8s-3.582 8-8 8zm0-40h-58c-4.418 0-8-3.582-8-8s3.582-8 8-8h58c4.418 0 8 3.582 8 8s-3.582 8-8 8zm113-263h-109.3c-4.8 0-8.7 3.9-8.7 8.7v43.03c0 4.26-3.22 7.98-7.47 8.25-4.66.3-8.53-3.39-8.53-7.98v-93c0-36.94 30.06-67 67-67s67 30.06 67 67z"
          />
        </svg>
      </div>
      <div class="texto-carrito">{{traducciones.find((item)=>item.item == 'Deseas pagar la cuenta').value}}</div>
    </div>
  </transition>

  <transition name="overlay">
    <div v-if="mostrarModal" class="carrito-sidebar">
      <div class="carrito-overlay" @click="cerrarCarrito()"></div>

      <transition name="carrito" appear>
        <div v-if="mostrarModal" class="carrito-container">
          <div class="carrito-header">
            <h4 class="fw-bold">{{traducciones.find((item)=>item.item == 'Cesta de compras').value}}</h4>

            <h4>{{ totalProductos }} {{traducciones.find((item)=>item.item == 'Productos').value}}</h4>
          </div>

          <div class="carrito-productos">
            <ProductoCarrito
              v-for="producto of carrito"
              :key="producto.id"
              :carrito="producto"
            />
            <div v-if="carrito.length === 0" class="carrito-vacio">
              <i class="bi bi-cart"></i>
              <p>{{traducciones.find((item)=>item.item == 'CestaVacia').value}}</p>
            </div>
          </div>

          <div class="carrito-contenido-total">
            <div class="text-end fs-5">
              <span>
                <span class="fw-bold">{{traducciones.find((item)=>item.item == 'Total').value}}: </span>
                <span class="carrito-total fw-bold"
                  >{{ totalPrecio.toFixed(2) }}{{ restaurante.moneda }}</span
                >
              </span>
            </div>
          </div>

          <div class="carrito-observaciones">
            <form action="">
              <div class="form-group fw-bold">
                <label for="observaciones ">{{traducciones.find((item)=>item.item == 'Observaciones').value}}:</label>
                <textarea
                  class="form-control mt-2"
                  id="observaciones"
                  rows="3"
                  v-model="data.observaciones"
                ></textarea>
              </div>
            </form>
          </div>

          <div class="carrito-propinas">
            <h6 class="fw-bold mb-3">
              {{traducciones.find((item)=>item.item == 'AgregarPropina').value}}
            </h6>

            <div class="btn-propina" role="group">
              <input
                type="radio"
                class="btn-check"
                name="propina"
                id="propina-1"
                autocomplete="off"
                v-model.number="data.propina"
                :value="1"
              />
              <label class="btn btn-outline-primary" for="propina-1">1€</label>

              <input
                type="radio"
                class="btn-check"
                name="propina"
                id="propina-2"
                autocomplete="off"
                v-model.number="data.propina"
                :value="2"
              />
              <label class="btn btn-outline-primary" for="propina-2">2€</label>

              <input
                type="radio"
                class="btn-check"
                name="propina"
                id="propina-3"
                autocomplete="off"
                v-model.number="data.propina"
                :value="5"
              />

              <label class="btn btn-outline-primary" for="propina-3">5€</label>
              <div>
                <div class="input-group">
                  <button
                    style="min-width: 2.5rem"
                    class="btn btn-decrement btn-outline-secondary btn-minus"
                    type="button"
                    @click="OnPropina(data.propina--)"
                  >
                    <strong>−</strong></button
                  ><input
                    type="number"
                    inputmode="decimal"
                    style="text-align: center"
                    class="form-control"
                    placeholder=""
                    v-model="data.propina"
                  /><button
                    @click="OnPropina(data.propina++)"
                    style="min-width: 2.5rem"
                    class="btn btn-increment btn-outline-secondary btn-plus"
                    type="button"
                  >
                    <strong>+</strong>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="carrito-formas-pago">
            <h6 class="fw-bold mb-3">{{traducciones.find((item)=>item.item == 'Formas de pago disponibles').value}}</h6>

            <div class="carrito-iconos-pago">
              <div class="carrito-pago-icono">
                <input
                  class="form-check-input"
                  type="radio"
                  name="metodo-pago"
                  id="efectivo"
                  :value="Number(2)"
                  v-model="data.formaPagoId"
                />
                <label class="form-check-label" for="efectivo">
                  {{traducciones.find((item)=>item.item == 'EnEfectivo').value}}
                </label>
              </div>
              <div class="carrito-pago-icono">
                <input
                  class="form-check-input"
                  type="radio"
                  name="metodo-pago"
                  v-model="data.formaPagoId"
                  id="tarjeta"
                  :value="Number(1)"
                />
                <label class="form-check-label" for="tarjeta">
                  <img
                    class="visa"
                    src="@/assets/images/visa-logo.svg"
                    alt=""
                  />
                </label>
              </div>
            </div>
          </div>

          <div class="carrito-botones">
            <button
              :disabled="!!loading"
              class="btn realizar-pago mb-3"
              @click="realizarPedido()"
            >
              <svg
                class="me-2"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m23 12c0 3.584-2.916 6.5-6.5 6.5s-6.5-2.916-6.5-6.5 2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5zm-7-1.161c0-.242.154-.41.292-.41h1.458c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-.5v-.179c0-.414-.336-.75-.75-.75s-.75.336-.75.75v.278c-.722.246-1.25.96-1.25 1.811 0 1.054.804 1.911 1.792 1.911h.417c.138 0 .292.169.292.411s-.154.41-.292.41h-1.459c-.414 0-.75.336-.75.75s.336.75.75.75h.5v.179c0 .414.336.75.75.75s.75-.336.75-.75v-.278c.722-.246 1.25-.96 1.25-1.811 0-1.054-.804-1.911-1.792-1.911h-.417c-.137 0-.291-.169-.291-.411z"
                />
                <path
                  d="m15 3.64v-.64c0-.55-.45-1-1-1h-.5c-.55 0-1 .45-1 1s-.45 1-1 1h-5c-.55 0-1-.45-1-1s-.45-1-1-1h-.5c-.55 0-1 .45-1 1v18c0 .55.45 1 1 1h10c.55 0 1-.45 1-1v-.63c.49.08.99.13 1.5.13.17 0 .33 0 .5-.01v.51c0 1.65-1.35 3-3 3h-10c-1.65 0-3-1.35-3-3v-18c0-1.65 1.35-3 3-3h10c1.65 0 3 1.35 3 3v.51c-.17-.01-.33-.01-.5-.01-.51 0-1.01.05-1.5.14z"
                />
              </svg>

              {{ loading ? traducciones.find((item)=>item.item == 'ProcesandoOrden').value : traducciones.find((item)=>item.item == 'Pagar la cuenta').value }}
            </button>
            <button class="btn border cerrar-carrito" @click="cerrarCarrito()">
              {{traducciones.find((item)=>item.item == 'Regresar').value}}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { reactive } from "@vue/reactivity";
import { computed, inject } from "@vue/runtime-core";

import ProductoCarrito from "./ProductoCarrito.vue";

export default {
  components: {
    ProductoCarrito,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const dialog = inject("$swal");

    const carrito = computed(() => store.state.carrito.carrito);
    const mostrarBoton = computed(() => store.state.carrito.showButton);
    const mostrarModal = computed(() => store.state.carrito.showModal);
    const loading = computed(() => store.state.carrito.loading);
    const restaurante = computed(() => store.state.restaurante.restaurante);
    if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Pedido') !== undefined
        || store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'AutoPedidos') !== undefined || store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Comandero') !== undefined
        )
        var muestraCesta = true;
    const data = reactive({
      observaciones: null,
      formaPagoId: null,
      propina: 0,
    });

    const totalProductos = computed(
      () => store.getters["carrito/totalCantidad"]
    );
    const totalPrecio = computed(() => store.getters["carrito/totalPrecio"]);

    const mostrarCarrito = () => {
      store.commit("carrito/mostrarPanel");
    };

    const cerrarCarrito = () => {
      store.commit("carrito/ocultarModal");
    };

    const OnPropina = (value) => {
      if (value < 0 || data.propina < 0) {
        data.propina = 0;
      }
    };

    const realizarPedido = async () => {
      if (!carrito.value.length) {
        dialog({
          position: "top-right",
          toast: true,
          showConfirmButton: false,
          icon: "error",
          title: "La cesta de compras esta vacia",
          timer: 2000,
        });
      } else if (data.formaPagoId === null) {
        dialog({
          position: "top-right",
          toast: true,
          showConfirmButton: false,
          icon: "error",
          title: "Debes de seleccionar un metodo de pago",
          timer: 2000,
        });
      } else {
        //Enviar data al enpoint
        const response = await store.dispatch("carrito/checkout", data);

        //Pedido se recibio OK
        if (response) {
          router.push({ name: "confirmacion" });
          store.commit("carrito/ocultarModal");
          store.commit("carrito/ocultarBoton");
        } else {
          dialog({
            position: "top-right",
            toast: true,
            showConfirmButton: false,
            icon: "error",
            title: "Ha ocurrido un error, contacta al proveedor",
            timer: 2000,
          });
        }
      }
    };
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale)
    return {
      carrito,
      loading,
      mostrarBoton,
      muestraCesta,
      mostrarCarrito,
      cerrarCarrito,
      realizarPedido,
      OnPropina,
      totalProductos,
      totalPrecio,
      mostrarModal,
      data,
      restaurante,
      traducciones
    };
  },
};
</script>

<style lang="scss">
.section-enter-active {
  animation: slideIn 0.4s;
}
.section-leave-active {
  animation: slideOut 0.4s;
}

//Animacion boton
@keyframes slideIn {
  from {
    bottom: 0px;
  }
  to {
    bottom: 75px;
  }
}
@keyframes slideOut {
  from {
    bottom: 75px;
  }
  to {
    bottom: 0px;
  }
}

.carrito-vacio {
  text-align: center;
  padding: 50px 0;

  i {
    font-size: 48px;
  }

  p {
    margin-top: 10px;
    font-size: 22px;
  }
}
.carrito-compras-boton {
  position: fixed;
  background: #4caf50;
  color: #fff;
  display: flex;
  padding: 15px;
  border-radius: 0;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  bottom: 75px;
  opacity: 1;
  left: 0;
  right: 0;
  z-index: 99;

  @media (min-width: 1280px) {
    width: 330px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 20px;
    border-radius: 8px;
  }

  @media (max-width: 960px) {
    font-size: 12px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.8) 0px 30px 60px;
  }

  .icon-compras {
    margin-right: 12px;

    svg {
      fill: #fff;
    }
  }
}

//Animación carrito
.overlay-enter-active {
  animation: mostrar 0.4s;
}

.overlay-leave-active {
  animation: ocultar 0.4s;
}

@keyframes mostrar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ocultar {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.carrito-enter-active {
  animation: abiertoCarrito 0.5s;
}

.carrito-leave-active {
  animation: cerradoCarrito 0.5s;
}

@keyframes abiertoCarrito {
  from {
    right: -100%;
  }
  to {
    right: 0%;
  }
}
@keyframes cerradoCarrito {
  from {
    right: 0;
  }
  to {
    right: -50%;
  }
}

//Estilos del sidebar del carrito de compras
.carrito-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1000;

  .carrito-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  .carrito-container {
    background: #fff;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    right: 0;
    width: 90%;

    @media (min-width: 1280px) {
      width: 800px;
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .producto-carrito {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin: 10px;
    padding: 20px 10px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.05);
    position: relative;
  }

  .carrito-header {
    text-align: center;
    padding: 16px;
    background: #f3f6f9;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 11px 13px rgba(0, 0, 0, 0.0844897);

    h4 {
      font-size: 14px;
      margin: 0;
    }
  }

  .carrito-eliminar-producto {
    position: absolute;
    top: -12px;
    right: 0;
    font-size: 25px;
    color: #b4b5b5;
    cursor: pointer;
    &:hover {
      color: #dc2626;
    }
  }

  .carrito-imagen {
    flex: 0 0 15%;

    img {
      border-radius: 4px;
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);
    }
  }

  .carrito-productos {
    padding-top: 15px;
    box-shadow: 0px 11px 13px rgba(0, 0, 0, 0.0844897);
  }

  .carrito-descripcion-producto {
    font-weight: bold;
    margin: 0;
    padding-right: 15px;

    @media (max-width: 698px) {
      font-size: 12px;
    }
  }

  .carrito-descripcion {
    flex: 0 0 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .carrito-imagen {
    border-radius: 8px;
    padding-right: 8px;
  }

  .carrito-descripcion-precios {
    display: flex;
    justify-content: space-between;
  }

  .carrito-precio {
    color: #4caf50;
    font-weight: bold;

    @media (max-width: 698px) {
      font-size: 12px;
    }
  }

  .carrito-cantidad {
    display: flex;
    align-items: center;
  }

  .carrito-iconos {
    font-size: 18px;
    color: #b4b5b5;
    cursor: pointer;

    &:hover {
      color: #4caf50;
    }
  }
  .carrito-cantidades {
    margin: 0 10px;
    font-size: 18px;
  }

  .carrito-observaciones {
    padding: 20px 20px 10px 20px;
  }

  .carrito-formas-pago {
    padding: 10px 20px;
  }

  .carrito-iconos-pago {
    display: flex;
  }

  .carrito-contenido-total {
    padding: 15px 20px 0;
  }

  .carrito-pago-icono {
    flex: 0 0 50%;
    margin-right: 6px;

    &:last-child() {
      margin-right: 0;
    }

    label {
      padding: 22px;
      background: #f3f6f9;
      margin: 2px;
      text-align: center;
      border-radius: 8px;
      width: 94%;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      left: -99999px;
    }

    svg {
      display: block;
      margin: 0 auto;
    }

    input:checked + label {
      background: #047857;
      color: #fff;
      font-weight: bold;
      box-shadow: 0px 11px 13px rgba(0, 0, 0, 0.0844897);
      transform: translateY(-5px);

      svg path {
        fill: #fff;
      }
    }
  }

  .carrito-botones {
    padding: 20px;
    display: flex;
    flex-direction: column;

    button {
      &:first-child {
        box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.25);
        opacity: 1;
      }

      opacity: 0.75;
    }
  }

  .carrito-propinas {
    padding: 10px 20px;

    .btn-propina > .btn-propina:not(:last-child) > .btn,
    .btn-propina > .btn:not(:last-child):not(.dropdown-toggle) {
      border-radius: 50px;
    }

    .btn-propina {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 16px;
      @media (max-width: 640px) {
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }

      label {
        display: inline-flex;
        cursor: pointer;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        align-items: center;
        padding: 8px 25px;
        background: #f3f6f9;
        border-radius: 50px;
        margin-bottom: 4px;
        color: #3c505a;
        border: 1px solid #ebeff3;
        justify-content: center;
      }

      .active {
        background-color: #047857;
        color: #fff;
      }
    }
  }

  .realizar-pago {
    display: flex;
    justify-content: center;
    padding: 20px;
    font-weight: bold;
    background-color: #4caf50;
    color: #fff;

    &:disabled {
      opacity: 0.8 !important;
    }

    &:hover {
      color: #fff;
      background: #047857;
    }
  }
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: #047857 !important;
  color: #fff !important;
}

.propina-input {
  width: 100%;
}

.visa {
  width: 70px;
}
</style>
