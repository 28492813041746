<template>
  <div class="container busqueda">
    <div class="row">
      <div class="col-12">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="searchQuery"
            name="buscar"
            :placeholder="traducciones.find((item)=>item.item == 'Buscar').value + '...'"
          />

          <button
            type="button"
            class="btn dropdown-toggle drop-buscar"
            data-bs-toggle="collapse"
            href="#alergenos-menu"
          >
            <span id="search_alergeno">
              <span class="d-none d-sm-inline-block">
                <span id="sortbyalergeno">{{traducciones.find((item)=>item.item == 'SortBy').value}} {{traducciones.find((item)=>item.item == 'Alergeno').value}}</span>
                <span class="caret d-none d-sm-inline-block"></span>
              </span>

              <div class="d-block d-sm-none">
                <svg
                  width="24px"
                  height="24px"
                  fill="#fff"
                  enable-background="new 0 0 511.084 511.084"
                  viewBox="0 0 511.084 511.084"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m436.58 74.516c-2.004-2.005-4.046-3.958-6.105-5.887-.061-.059-.113-.123-.175-.18-.068-.063-.141-.115-.209-.177-96.168-93.408-262.59-90.625-355.561 6.247-101.792 98.034-98.869 273.442 6.175 368 .108.107.203.221.315.325.127.118.263.219.392.332 96.212 93.062 262.334 90.111 355.168-6.612 99.312-95.286 99.318-266.762 0-362.048zm-407.032 181.025c-.309-189.877 222.521-295.157 368.995-175.055l-67.469 72.272v-52.623c-.793-19.902-29.215-19.887-30 0v34.589l-30.526 21.757v-85.006c-.793-19.902-29.215-19.887-30 0v85.006l-30.526-21.757v-34.589c-.793-19.902-29.215-19.887-30 0v214.631l-89.156 95.54c-39.59-42.064-61.318-96.742-61.318-154.765zm241 32.555v-28.968l30.526-21.757v28.968zm30.526 15.083v23.769c0 4.227-2.834 8.709-7.79 12.345l-22.736 14.233v-28.59zm-30.526-80.891v-28.968l30.526-21.757v28.968zm-60.527 44.051v-28.968l30.526 21.757v28.968zm30.527 58.597v28.59l-22.737-14.233c-4.956-3.636-7.79-8.118-7.79-12.345v-23.769zm-30.527-124.404v-28.968l30.526 21.757v28.968zm205.347 214.821c-78.261 81.445-216.742 88.214-302.572 15.425l74.774-80.128c3.369 4.962 7.786 9.483 13.156 13.31.244.174.493.34.747.5l39.074 24.46v50.671c.793 19.902 29.215 19.887 30 0v-50.671l39.074-24.46c.254-.159.503-.326.747-.5 13.158-9.379 20.705-22.869 20.705-37.012v-130.227l89.37-95.733c82.887 84.838 80.609 232.128-5.075 314.365z"
                  />
                </svg>
              </div>
            </span>
          </button>

          <div class="input-group-btn search-panel">
            <button
              type="button"
              class="btn dropdown-toggle drop-buscar"
              data-bs-toggle="collapse"
              href="#filtro-menu"
            >
              <span id="search_concept">
                <span class="d-none d-sm-inline-block">
                  <span id="sortby">{{traducciones.find((item)=>item.item == 'SortBy').value}}</span>
                  <span class="caret d-none d-sm-inline-block"></span>
                </span>

                <div class="d-block d-sm-none">
                  <svg
                    height="24px"
                    fill="#fff"
                    viewBox="1 -75 511.9995 511"
                    width="24px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m510.066406 171.917969c-42.816406-90.140625-140.175781-171.417969-254.066406-171.417969-128.148438 0-220.261719 100.246094-254.066406 171.417969-2.578125 5.429687-2.578125 11.734375 0 17.164062 42.816406 90.140625 140.175781 171.417969 254.066406 171.417969 128.148438 0 220.261719-100.25 254.066406-171.417969 2.578125-5.429687 2.578125-11.734375 0-17.164062zm-254.066406 148.582031c-92.140625 0-168.535156-60.722656-213.347656-140 44.625-78.953125 120.972656-140 213.347656-140 92.132812 0 168.535156 60.714844 213.347656 140-44.625 78.949219-120.972656 140-213.347656 140zm0-240c-55.140625 0-100 44.859375-100 100s44.859375 100 100 100 100-44.859375 100-100-44.859375-100-100-100zm0 160c-33.082031 0-60-26.917969-60-60 0-33.085938 26.917969-60 60-60 33.085938 0 60 26.914062 60 60 0 33.082031-26.914062 60-60 60zm0 0"
                    />
                  </svg>
                </div>
              </span>
            </button>
            <!--  
            <ul class="dropdown-menu dropdown-buscar" role="menu">
              <li><a href="/mosaico.html">Ordenar por mosaico</a></li>
              <li>
                <a href="/mosaico-destacados.html">Ordenar por defecto</a>
              </li>
              <li><a href="/lista-deseos.html">Ordenar por nombre</a></li>
              <li><a href="/lista-deseos.html">Ordenar por precio</a></li>
            </ul> -->
          </div>
        </div>
      </div>

      <div class="col-12">
        <!-- {{!-- Alergenos Menu --}} -->
        <Alergenos />
        <Filtros />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Alergenos from "./Alergenos.vue";
import Filtros from "./Filtros.vue";

export default {
  components: {
    Alergenos,
    Filtros,
  },
setup() {
const store = useStore();
const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
   return { traducciones} 
  },
  computed: {
    resultQuery(){
      if(this.searchQuery){
      return this.categorias.filter((item)=>{
        return this.searchQuery.toLowerCase().split(' ').every(v => item.nombre.toLowerCase().includes(v))
      })
      }else{
        return this.categorias;
      }
    }
  }
}
</script>

<style lang="scss">
.busqueda {
  margin: 15px auto 0;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);
  border: 1px solid #e8eff3;

  .drop-buscar {
    margin-left: 25px;
    background: #46b975;
    color: #fff;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    border: 0;

    @media (max-width: 698px) {
      margin-left: 8px;
    }
  }

  .mobile-icono {
    display: none;

    @media (max-width: 698px) {
      display: block;
    }
  }

  @media (max-width: 698px) {
    .dropdown-toggle::after {
      display: none;
    }
  }

  input {
    border-radius: 4px !important;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);
  }

  .dropdown-buscar {
    padding: 5px 20px;

    li {
      padding-bottom: 5px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .alergenos-contenedor {
    padding: 25px 25px 0;

    @media (max-width: 698px) {
      padding: 15px 0 0;
    }

    input[type="checkbox"] {
      display: none;
    }

    input:checked ~ .alergeno {
      background: #46b975;
      font-weight: bold;
      color: #fff;
    }

    label {
      display: block;
    }

    .alergeno {
      display: inline-flex;
      cursor: pointer;
      width: 100%;
      font-size: 12px;
      align-items: center;
      padding: 8px 15px;
      background: #f3f6f9;
      border-radius: 50px;
      margin-bottom: 10px;
      color: #3c505a;
      border: 1px solid #ebeff3;
    }

    ul {
      column-count: 4;
      column-gap: 1rem;
      padding: 0;
      margin: 0;
      list-style: none;

      @media (max-width: 763px) {
        column-count: 2;
      }
    }

    img {
      width: 22px;
      margin-right: 8px;
    }
  }

  #filtro-menu {
    margin-top: 25px;

    .contenido-filtro {
      .titulo {
        font-size: 16px;
        text-align: left;
        font-weight: bold;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: center;
      }

      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      input {
        display: none;

        &:checked ~ .opcion {
          background: #46b975;
          color: #fff;
          font-weight: bold;
        }
      }

      .opcion {
        cursor: pointer;
        width: 100%;
        font-size: 12px;
        align-items: center;
        padding: 8px 15px;
        background: #f3f6f9;
        border-radius: 50px;
        margin-bottom: 4px;
        color: #3c505a;
        border: 1px solid #ebeff3;
        margin-right: 8px;
        margin-bottom: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
