<template>
  <div class="loading">
    <img v-if="restaurante.loading == null || restaurante.loading==''" class="logo" :src="`https://${restaurante.path}/uploads/images/distribuidores/logo.png`" alt="" />
    <img v-else class="logo" :src="`https://${restaurante.path}/uploads/images/distribuidores/${restaurante.loading}`" alt="" />

    <span class="loading-container">
      <div></div>
      <div></div>
      <div></div>
    </span>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed} from "@vue/runtime-core";
export default {
  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);

return {restaurante
}
}
};
</script>

<style scoped>
.logo {
  width: 180px;
  animation: pulse 8s linear infinite;
}
.loading {
  display: flex;
  position: relative;
  /* width: 80px; */
  height: 160px;
  margin: 25px auto 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  flex-direction: column;
  align-items: center;
}
.loading-container {
  width: 55px;
  height: 80px;
  position: relative;
}
.loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #75dda0;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading div:nth-child(1) {
  left: 8px;
  animation: loading1 0.6s infinite;
}
.loading div:nth-child(2) {
  left: 8px;
  animation: loading2 0.6s infinite;
}
.loading div:nth-child(3) {
  left: 32px;
  animation: loading2 0.6s infinite;
}
.loading div:nth-child(4) {
  left: 56px;
  animation: loading3 0.6s infinite;
}
@keyframes loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
