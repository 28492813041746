<template>
  <Layout>
    <div class="card-container mobile-m">
      <Card
        v-for="categoria in categorias"
        :key="categoria.id"
        :categoria="categoria"
      />
    </div>
  </Layout>
</template>

<script>
import { useStore } from "vuex";

// @ is an alias to /src
import Card from "@/components/card/Card.vue";
import Layout from "@/layout/Layout.vue";
import { computed } from "@vue/runtime-core";

export default {
  name: "Home",
  components: {
    Card,
    Layout,
  },
  setup() {
    const store = useStore();

    const categorias = computed(
      () => store.getters["categorias/OrdenarPorIdioma"]
    );

    return { categorias };
  },
};
</script>
