<template>
  <Layout>
    <div class="container">
      <div class="table-responsive mt-5 pedidos">
        <h1 class="mb-5 fw-bold">Historial de ordenes</h1>

        <table class="table" v-if="ordenes.length > 0">
          <thead class="thead-dark">
            <tr>
              <th scope="col"># Pedido</th>
              <th scope="col">Total</th>
              <th scope="col">Forma de pago</th>
              <th scope="col">Order</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orden of ordenes" :key="orden.id">
              <th scope="row">{{ orden.id }}</th>
              <td>{{ orden.pedido.total.toFixed(2) }} €</td>
              <td>
                {{
                  orden.pedido.paymentData
                    ? order.paymentData.nombre
                    : "Efectivo"
                }}
              </td>
              <td>Mirar orden</td>
              <td class="completado" :v-if="orden.pedido.success">
                <i class="bi bi-check-circle-fill"> Completado</i>
              </td>
            </tr>

            <!-- <tr>
              <th scope="row">9874</th>
              <td>56.79€</td>
              <td>Efectivo</td>
              <td>Mirar orden</td>
              <td class="fallo">
                <i class="bi bi-x-circle-fill"></i>
                Cancelado
              </td>
            </tr> -->
          </tbody>
        </table>

        <h3 class="text-center" v-else>No se ha realizado ninguna orden</h3>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from "vuex";

import Layout from "@/layout/Layout.vue";
import { computed } from "@vue/reactivity";

export default {
  components: {
    Layout,
  },

  setup() {
    const store = useStore();

    const ordenes = computed(() => store.state.ordenes.ordenes);

    return {
      ordenes,
    };
  },
};
</script>

<style lang="scss" scoped>
.pedidos {
  @media (max-width: 698px) {
    font-size: 11px;
  }

  .completado {
    color: #28a745;
    font-weight: bold;
  }

  .fallo {
    color: #dc3545;
    font-weight: bold;
  }
}
</style>