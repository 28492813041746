<template>
  <img
    data-dismiss="modal"
    v-if="producto.logo"
    :src="`https://${restaurante.path}/uploads/images/productos/${restaurante.eid}/${restaurante.id}/${producto.logo}`"
    alt="Imagen"
   
    class="img-fluid img-descripcion"
  />
  <div class="no-fotografia" v-else>
    <div
      data-bs-toggle="collapse"
      data-bs-target="#agregar-fotografia"
      class="agregar-foto"
    >
      {{traducciones.find((item)=>item.item == 'Colabora subiendo una foto').value}}
      <i class="ms-2 bi bi-upload"></i>
    </div>
    <img
       id='productoimg'
      :src="`https://${restaurante.path}/uploads/images/productos/no-imagen-producto.jpg`"
      alt="Imagen"
      class="ng-scope img-fluid img-descripcion"
    />
  </div>

  <div class="collapse agregar-fotografia" id="agregar-fotografia">
    <p>
      {{traducciones.find((item)=>item.item == 'PermisoFoto').value}}
    </p>

    <form>
      <div class="form-group">
        <!-- <label for="fotografia-nombre">{{traducciones.find((item)=>item.item == 'Nombre').value}}</label> -->
        <input
          type="email"
          class="form-control mb-3"
          id="fotografia-nombre"
          :placeholder="traducciones.find((item)=>item.item == 'TuNombre').value"
        />
      </div>
      <div class="form-group">
        <!-- <label for="exampleFormControlFile1">{{traducciones.find((item)=>item.item == 'SubirFoto).value}}</label> -->
        <input
          type="file"
          class="form-control mb-3"
          id="FormControlFile"
          name="FormControlFile"
        />
      </div>

      <button type="submit" class="btn w-100 btn-verde">
        {{traducciones.find((item)=>item.item == 'EnviarFoto').value}}
      </button>
    </form>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { API_URL } from "../../utils/constants";
export default {
  props: ["producto"],

  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return { restaurante,traducciones };
  },
};
const $ = require('jquery');
window.$ = $;
$(function() { 
    $("#FormControlFile").on("change", function(){
       var input = this;
       var reader = new FileReader();
        if (input.files && input.files[0]){
            reader.onload = function (e) {
                $('#productoimg').attr('src', e.target.result);
            }
            reader.readAsDataURL(input.files[0]);
        }
    });
   $('form').on('submit', function () {
                        var file_data = $("#FormControlFile").prop('files')[0];   
                        var form_data = new FormData();                                 form_data.append('sucursal',JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.id);
form_data.append('empresa',JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.eid);
form_data.append('producto',JSON.parse(localStorage.getItem('vuex')).productos.producto.id);

                        form_data.append('file', file_data);
                        $.ajax({
                            url: API_URL+'/enviarFoto', // point to server-side controller method
                            dataType: 'text', // what to expect back from the server
                            cache: false,
                            contentType: false,
                            processData: false,
                            data: form_data,
                            type: 'post',
                            success: function (response) {
                                console.log(response); 
                            },
                            error: function (response) {
                                $('#msg').html(response); // display error response from the server
                            }
                        });
                        return false;
                    });
});

</script>

<style lang="scss" scoped>
.agregar-foto {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: #46b975;
  text-align: center;
  padding: 15px;
  font-weight: 700;
  bottom: 0;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

button {
  background-color: #4caf50 !important;
  color: #fff;
  font-weight: bold;
  color: 16px;
  padding: 15px 0;
}

#agregar-fotografia {
  padding: 25px 25px 75px;
}
</style>
