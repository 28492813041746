<template>
  <div v-if="tamanos && tamanos.length>0" class="tamano-platos">
    <h4 class="mb-3">{{traducciones.find((item)=>item.item == 'Sizes').value}}</h4>

    <div v-for="item in tamanos"
      class="btn-group tamanos"
      role="group"
      aria-label="Basic radio toggle button group"
    >
      <input 
        type="radio"
        class="btn-check"
        name="tamano-plato"
        :id="`tamano-plato-${item.id}`"
        autocomplete="off"
        :checked="`${item.precio}==0`"
        :value="`${item.precio}`"
        @click="agregarValores('`${item.nombre}`', '`${item.precio}`')"
      />
      <label v-if="item.precio == 0" class="btn btn-outline-primary" :for="`tamano-plato-${item.id}`"
        >{{item.nombre}}</label
      >
      <label v-else class="btn btn-outline-primary" :for="`tamano-plato-${item.id}`"
        >{{item.nombre}} {{item.precio}}</label
      >

      
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
export default {
  props: ["productos"],
  setup() {
    const store = useStore();
    const agregarValores = (nombre, precio) => {
      let producto = {
        nombre: nombre,
        precio: precio,
      };

      store.dispatch("productos/setTamanoExtra", producto);
    };
const tamanos = computed(() => store.state.restaurante.restaurante.sizes.filter( item => item.locale === store.state.idiomas.options.locale  && item.producto == store.state.productos.producto.id));
const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale);
    return { agregarValores,tamanos,traducciones };
  },
};
</script>

<style lang="scss" scoped>
.tamano-platos {
  margin-bottom: 30px;
}
.tamanos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .btn-radio {
    background-color: rgba(195, 195, 195, 0.251475);
    border-radius: 8px;

    &.active {
      background: #01662a;
      color: #fff;
      font-weight: 600;
    }
  }
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background: #01662a;
  color: #fff;
  font-weight: bold;
}

label.btn.btn-outline-primary {
  background-color: rgba(195, 195, 195, 0.251475);
  border: 0;
  color: #000;
  padding: 12px 0;
}
</style>
